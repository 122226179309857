<template>
  <div class="container-max-width mx-auto pt-4">
    <v-row class="text-center" v-if="this.isLoading || this.temporaryPageLoad">
      <v-col cols="12">
        <div id="syncio-loading-logo">
          <loading-syncio-logo></loading-syncio-logo>
        </div>
      </v-col>
    </v-row>
    <v-row class="text-center" v-else-if="this.tokenError">
      <v-col cols="12">
        <p class="text-h1">Please try again, Error occurred during installation. Or, contact helpdesk for support.</p>
      </v-col>
    </v-row>
    <v-row class="page-wrapper" v-else>
      <v-col>
        <v-row style="transform: translateX(-6px); transition: .25s;" ref="selectStoreSteps">
          <v-col cols="12" class="align-center">
            <div class="text-center">
              <svg width="120px" height="30px" viewBox="0 0 100 30">
                <circle cx="10" cy="10" r="9" class="filled"/>
                <line x1="19" y1="10" x2="31" y2="10" class="line"/>
                <circle cx="40" cy="10" r="9" class="not-filled"/>
                <line x1="61" y1="10" x2="49" y2="10" class="line"/>
                <circle cx="70" cy="10" r="9" class="not-filled"/>
                <line x1="91" y1="10" x2="79" y2="10" class="line last-step"/>
                <circle cx="100" cy="10" r="9" class="not-filled last-step"/>
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row class="heading-text-wrapper">
          <v-col class="primary--text" cols="12">
            <p class="text-h8 text-center mb-2 font-weight-bold font-primary">STEP 1</p>
            <p class="text-h1 text-center pb-5 mb-2">Select a Store Type</p>
          </v-col>
        </v-row>
        <v-row class="buttons-wrapper text-center">
          <v-col class="d-flex justify-space-around" @mouseenter="storeTypeHoverHandler('source')">
            <SourceStoreButton @click.native="setShopType('source')"/>
          </v-col>
          <v-col class="d-flex justify-space-around" @mouseenter="storeTypeHoverHandler('destination')">
            <DestinationStoreButton @click.native="setShopType('destination')"/>
          </v-col>
        </v-row>
        <v-row class="read-guide">
          <v-col cols="12">
            <p class="text-body-1 text-center mt-4 mb-0">Not sure? Read our guide <a href="http://help.syncio.co/en/articles/4641734-am-i-a-source-or-a-destination-store" class="text-decoration-none" target="_blank">here.</a></p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import SourceStoreButton from "@/views/registration/components/SourceStoreButton";
import DestinationStoreButton from "@/views/registration/components/DestinationStoreButton";
import { mapGetters } from "vuex";
import {LOGIN_SHOPIFY, REGISTER_SHOP_TYPE, SAVE_SHOPIFY_TOKEN} from "@/store/actions.type";
import LoadingSyncioLogo from "@/views/registration/components/LoadingSyncioLogo";

export default {
  name: "SelectStoreType",
  data() {
    return {
      tokenError: false,
      temporaryPageLoad: false
    }
  },
  components: { LoadingSyncioLogo, DestinationStoreButton, SourceStoreButton },
  computed : {
    ...mapGetters("auth", ["isLoading"]),
    ...mapGetters("shop", ["newShop"]),
  },
  created() {
    this.temporaryPageLoad = true;
    let payLoad = this.$route.query;
    payLoad.store_id = payLoad.shop;

    this.$store.dispatch(`auth/${SAVE_SHOPIFY_TOKEN}`, payLoad).then((response) => {
      if (response.data.success == false){
        this.tokenError = true;
      }else{
        let storeName = response.data.store_name;
        this.$store.dispatch(`auth/${LOGIN_SHOPIFY}`, {storeName}).then(() => {
          this.$store.dispatch(`shop/loadNewShop`).then((data) => {
            if (data.success){
              this.temporaryPageLoad = false
            }
          });
        })
      }
    })
  },
  methods : {
    setShopType(type) {
      this.temporaryPageLoad = true;
      this.$store.dispatch(`shop/${REGISTER_SHOP_TYPE}`, { store_id: this.newShop.id,  type: type }).then((data) => {
        if (data.success){
          if (type === 'destination'){
            this.$router.push({name: 'shopify.connect-and-invite-store'});
          }else{
            this.$router.push({name: 'shopify.connect-and-invite-store-via-source-store'});
            // this.$router.push('/dashboard');
          }
        }
      })
    },
    storeTypeHoverHandler(storeType) {
      if(storeType == 'source') {
        this.$refs.selectStoreSteps.classList.add('hide-one-step');
      } else {
        this.$refs.selectStoreSteps.classList.remove('hide-one-step');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container-max-width{
  max-width: 700px;
}
.buttons-wrapper{
  align-content: center;
}
#syncio-loading-logo {
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.parent-store-button {
  border-radius: 20px;
  color: #161616;
  padding: 42px 40px;
  width: 350px;
  transition: all .25s;
  border: 2px solid transparent;

  &:hover {
    border-color: #539dbd !important;
  }
}
.read-guide {
  font-size: 14px;

  a {
    color: #539dbd;
  }
}

.last-step {
  transition: .25s;
}

.hide-one-step {
  transform: translateX(8px) !important;

  .last-step {
    opacity: 0
  }
}
</style>
