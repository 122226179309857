<template>
  <v-card class="white parent-store-button" elevation="0">
    <span class="mt-6">
      <v-img class="parent-store-image" src="~@/assets/images/source.png" max-width="164px" contain></v-img>
      <p class="text-h4 mt-6 mb-3">Source Store</p>
      <p class="text-caption small-text">I am the source of the truth.
      I hold the master inventory & product information. I can also fulfill and ship products to the customer.</p>
      <p class="text-caption small-text mb-0">Examples:</p>
      <ul class="text-caption small-text pl-5">
        <li>The primary online store</li>
        <li>Connected to warehouse source/location</li>
        <li>A supplier to other retailers</li>
        <li>A brand store</li>
      </ul>
    </span>
    <v-btn class="primary rounded-lg mt-6" block height="48px">
      <div class="text-body-1 font-weight-bold">
        Choose this store type
      </div>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "SourceStoreButton"
}
</script>

<style scoped>
.parent-store-button{
  padding: 16px;
}
.parent-store-image{
  margin: auto;
}
.small-text{
  text-align: left;
  font-size: 11px;
}
.parent-store-image {
  height: 74px;
}
</style>
