<template>
  <v-card class="white parent-store-button" elevation="0">
    <span class="mt-6">
      <v-img class="parent-store-image" src="~@/assets/images/destination.png" max-width="164px" contain></v-img>
      <p class="text-h4 mt-6 mb-3">Destination Store</p>
      <p class="text-caption small-text">I sync inventory and product information from the source store. I can also send orders to the source store to fulfill.</p>
      <p class="text-caption small-text mb-0">Examples:</p>
      <ul class="text-caption small-text pl-5">
        <li>Secondary retail location (different region)</li>
        <li>Third party retailer</li>
        <li>Drop shipping store</li>
        <li>Alternative sales channel (wholesale/retail)</li>
      </ul>
      <v-btn class="primary rounded-lg mt-6" block height="48px">
        <div class="text-body-1 font-weight-bold">Choose this store type</div>
      </v-btn>
    </span>
  </v-card>
</template>

<script>
export default {
  name: "DestinationStoreButton"
}
</script>

<style scoped>
.parent-store-button {
  padding: 16px;
}
.parent-store-image {
  margin: auto;
}

.small-text {
  text-align: left;
  font-size: 11px;
}

.parent-store-image {
  height: 74px;
}
</style>
